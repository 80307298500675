import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby'

import { usePageContext } from '../context/pageContext';

const StyledServicesText = styled.div`
  margin: 550px auto 50px auto;
  width: 450px;

  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 350px;
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    width: 270px;
    margin-top: 450px;
  }
`

const StyledSection = styled.div`
  margin-bottom: 45px;
`

const StyledTitle = styled.h3`
  font-family: ${props => props.theme.fonts.baskerville};
  // color: ${props => props.theme.colors.green};
  font-weight: normal;
  margin-bottom: 14px;

    /* Fallback: Set a background color. */
    background-color: red;

    /* Create the gradient. */
    background-image: ${props => props.theme.colors.blue};

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
`

const StyledParagraph = styled.p`
  margin: 0 0 0 15px;
`

const ServicesPhoto = () => {
  const {langKey: currentLang} = usePageContext();

  const data = useStaticQuery(graphql`
  query AgencyValues {
    allAgencyValuesJson {
      edges {
        node {
          title {
            en
            es
          }
          contentLast {
            en
            es
          }
          contentFirst {
            en
            es
          }
        }
      }
    }
  }
  `)

  const agencyValues = data.allAgencyValuesJson.edges.map((item, index) => (
    <>
      <StyledSection key={index} data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="1000">
        <StyledTitle>{item.node.title[currentLang]}</StyledTitle>
        <StyledParagraph>{item.node.contentFirst[currentLang]}</StyledParagraph>
        <StyledParagraph>{item.node.contentLast[currentLang]}</StyledParagraph>
      </StyledSection>
    </>
  ))

  return (
    <StyledServicesText>
      {agencyValues}
    </StyledServicesText>
  );
}

export default ServicesPhoto;