import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby'

import { usePageContext } from '../context/pageContext';

const StyledServicesText = styled.div`
  margin: 0 auto;
  width: 450px;

  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 350px;
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    width: 270px;
  }
`

const StyledSection = styled.div`
  margin-bottom: 45px;
`

const StyledTitle = styled.h3`
  font-family: ${props => props.theme.fonts.baskerville};
  // color: ${props => props.theme.colors.green};
  font-weight: normal;
  margin-bottom: 14px;
  text-align: center;
  font-size: 32px;

    /* Fallback: Set a background color. */
    background-color: red;
    
    /* Create the gradient. */
    background-image: ${props => props.theme.colors.blue};
    
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    font-size: 24px;
  }
`

const StyledItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    white-space: nowrap;
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    flex-wrap: wrap;
  }
`

const StyledDots = styled.div`
  display: flex;
  margin: 4px;

  & div {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${props => props.theme.colors.blue};
    margin: 0 2px;
  }
`

const ServicesPhoto = () => {
  const {langKey: currentLang} = usePageContext();

  const data = useStaticQuery(graphql`
    query Services {
      allServicesJson {
        edges {
          node {
            services {
              en
              es
            }
            title {
              en
              es
            }
          }
        }
      }
    }
  `)

  const services = data.allServicesJson.edges.map((item, index) => (
    <>
      <StyledSection key={index} data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="1000">
          <StyledTitle>{item.node.title[currentLang]}</StyledTitle>
          <StyledItems>
            <StyledDots>
              <div />
              <div />
            </StyledDots>
            {
              item.node.services.map((desc,i) => (
                <React.Fragment key={i}>
                  <p>{desc[currentLang]}</p>
                  <StyledDots>
                    <div />
                    <div />
                  </StyledDots>
                </React.Fragment>
              ))
            }
          </StyledItems>
        </StyledSection>
    </>
  ))

  return (
    <StyledServicesText>
      {services}
    </StyledServicesText>
  );
}

export default ServicesPhoto;