import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import { usePageContext } from '../context/pageContext';

const StyledServicesPhoto = styled.div`
  margin: 30px auto;
  width: 400px;
  position: relative;
  z-index: 2;

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    width: 250px;
    margin-top: 90px;
  }

  & .img {
    /* width: 400px;
    height: auto; */
    display: block;
    right: -15px;
    position: absolute;
    z-index: 2;
    filter: grayscale(100%) contrast(1);

    &:hover,
      &:focus {
        filter:none;
      }

    @media (min-device-width : 320px) and (max-device-width : 667px) {
      width: 250px;
      right: -15px;
    }
  }
`

const StyledText = styled.p`
  font-family: ${props => props.theme.fonts.baskerville};
  font-weight: bold;
  font-size: 75px;
  transform: rotate(-90deg);
  margin: 0;
  white-space: nowrap;
  position: absolute;
  top: 200px;
  right: 233px;
  z-index: 1;
  animation: animate 3s linear infinite;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(70,152,142,1) 0%, rgba(92,174,103,1) 49%, rgba(138,197,65,1) 100%);
  background-repeat: no-repeat;
  background-size: 81%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);

  @keyframes animate {
    0% {
      background-position: -500%;
    }
    100% {
      background-position: 500%;
    }
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    right: 112px;
    top: 145px;
    font-size: 60px;
  }
`

// const StyledPhoto = styled.img`
//   width: 400px;
//   height: auto;
//   display: block;
//   right: -15px;
//   position: absolute;
//   z-index: 2;
//   filter: grayscale(100%) contrast(1);

//   &:hover,
//     &:focus {
//       filter:none;
//     }

//   @media (min-device-width : 320px) and (max-device-width : 667px) {
//     width: 250px;
//     right: -15px;
//   }
// `

const ServicesPhoto = () => {
  const {langKey: currentLang} = usePageContext();

  const data = useStaticQuery(graphql`
    query servicesPhoto {
      servicesPhotoJson {
        weCode {
          en
          es
        }
      }
    }
  `)

  return (
    <StyledServicesPhoto data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="1000">
      <StaticImage src="../images/clouds.webp" alt="Clouds" className="img" placeholder="blurred" width={400} height={600}/>
      <StyledText>{data.servicesPhotoJson.weCode[currentLang]}</StyledText>
    </StyledServicesPhoto>
  );
}

export default ServicesPhoto;
